<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <ReportViewer v-if="reppath" v-bind:json_data = jsondata v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="200" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">

              <div class="card-header header-elements-inline bg-teal-700" style="padding: 10px;">
                <h3 class="card-title">Register Filters</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" type="date" class="form-control"  v-model="from_date"/>
                  </div>


                  <label class="col-md-3 col-form-label">Upto:</label>

                  <div class="col-md-3">
                    <input id= "txtupto" type="date" class="form-control"  v-model="upto_date"/>
                  </div>

                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Report<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'

export default {
  name: 'member_list',
  components: {
    ReportViewer,
  },
  store,
  data()  {
    return {
      reppath: '',
      filename: 'member_list.mrt',
      ason_date:  moment().format('YYYY-MM-DD'),
      jsondata : {},
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
    }
  },
  mounted() {
    const self = this;
    self.$modal.show('filter_window');
    self.$data.jsondata = {"ok": true,"status": 200,"data": []};
    self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/lms/members`;
    self.loadFilterData();
  },
  methods: {
    afterfilterwindow_visible () {
      this.$data.reppath = '';
      $('#txtfrom').focus();
    },
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

    },
    loadData(){
      const self = this;

      self.$modal.hide('filter_window');

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/members?from=${self.$data.from_date}&upto=${self.$data.upto_date}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.jsondata = resp;
            self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/lms/members?from=${self.$data.from_date}&upto=${self.$data.upto_date}`;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>

</style>
